export enum AuthActionType {
    ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST',
    ADMIN_LOGOUT_REQUEST = 'ADMIN_LOGOUT_REQUEST',
    ADMIN_CHANGE_LANGUAGE = 'ADMIN_CHANGE_LANGUAGE',
    ADMIN_SHOW_LOADER = 'ADMIN_SHOW_LOADER',
    ADMIN_CHANGE_ABILITIES = 'ADMIN_CHANGE_ABILITIES',
    ADMIN_UPDATE_TIMEZONE = 'ADMIN_UPDATE_TIMEZONE',
    ADMIN_UPDATE_USERNAME = 'ADMIN_UPDATE_USERNAME',
    ADMIN_STORE_MENU_DETAILS = 'ADMIN_STORE_MENU_DETAILS',
    ADMIN_UPDATE_NOTIFICATION_COUNT = 'ADMIN_UPDATE_NOTIFICATION_COUNT',
    SHOW_LOADER = 'SHOW_LOADER' 
}
