import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IApplicationState } from './state/app-state';

import { createStateSyncMiddleware } from 'redux-state-sync';

import rootReducer from './reducers/root-reducer';
import { loadStorage, saveStorage } from '../utils/localStorage';

const middlewares = [createStateSyncMiddleware({ broadcastChannelOption: { type: 'localstorage' } })];
const persistentState: IApplicationState = loadStorage();

const store = createStore(rootReducer, persistentState, composeWithDevTools(applyMiddleware(...middlewares, thunk)));

store.subscribe(() => {
    saveStorage(store.getState());
});

export default store;
