// const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;
const PATH_PREFIX = '/screener';
export const Routing = {
    /* Developer Routes */
    Login: `${PATH_PREFIX}/login`,
    SignUp: `${PATH_PREFIX}/sign-up`,
    ForgotPassword: `${PATH_PREFIX}/forgot-password`,
    ResetPassword: `${PATH_PREFIX}/reset-password`,
    Dashboard: `${PATH_PREFIX}/dashboard`,
    IPOs: `${PATH_PREFIX}/ipos`,
    MainBoardIPOs: `${PATH_PREFIX}/mainboard-ipos`,
    SMEIPOs: `${PATH_PREFIX}/sme-ipos`,
    ListedIPOs:`${PATH_PREFIX}/listed-ipos`,
    MainBaordIPODetails: `${PATH_PREFIX}/mainboard-ipos/:id?/:moduleId?`,
    SMEIPODetails: `${PATH_PREFIX}/sme-ipos/:id?/:moduleId?`,
    ListedIPODetails: `${PATH_PREFIX}/listed-ipos/:id?/:moduleId?`,
    Pancards: `${PATH_PREFIX}/pancards`,
    Allotment: `${PATH_PREFIX}/allotment`,
    ViewAllotment:`${PATH_PREFIX}/allotment/:id?`,
};
