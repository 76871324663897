/* eslint-disable @typescript-eslint/no-explicit-any */
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { IAuthState, IGeneralState } from './auth-state';
import { Routing } from '../../shared/constants/routing';

/**
 * General state
 */
export interface IApplicationState {
    IPOData: IAuthState;
    GeneralData: IGeneralState;
}

/**
 * General action
 */
export interface IAction<T = unknown> {
    type: string | number;
    payload?: T;
}

export const isUserAuthenticated = connectedRouterRedirect({
    redirectPath: (state: IApplicationState) => {
        return Routing.Dashboard;
    },
    allowRedirectBack: false,
    authenticatedSelector: (state: IApplicationState) => {
        if (state?.IPOData && state?.IPOData?.authConfigs && state?.IPOData?.authConfigs?.accessToken) {
            return true;
        }
        return false;
    },
    wrapperDisplayName: 'Authenticated',
}) as any;

export const isNotAuthenticated = connectedRouterRedirect({
    redirectPath: Routing.Dashboard,
    allowRedirectBack: false,
    authenticatedSelector: (state: IApplicationState) => {
        if (!state?.IPOData || !state?.IPOData?.authConfigs || !state?.IPOData?.authConfigs?.accessToken) {
            return true;
        }
        return false;
    },
    wrapperDisplayName: 'IsNotAuthenticated',
}) as any;
