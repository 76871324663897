import React, { lazy } from 'react';
import { Routing } from './constants/routing';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { isNotAuthenticated, isUserAuthenticated } from '../store/state/app-state';
import { useDispatch } from 'react-redux';
import { showLoader } from '../store/actionTypes/general-actions';

/* Import lazy routes */
const MainLayout = lazy(() => import('../layouts/MainLayout'));
const ForgotPassword = lazy(() => import('../pages/Account/ForgotPassword'));
const SignUp = lazy(() => import('../pages/Account/SignUp'));
const Login = lazy(() => import('../pages/Account/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const MainboardIpos = lazy(() => import('../pages/IPOs/MainlineIpo'));
const SMEIpos = lazy(() => import('../pages/IPOs/SMEIpo'));
const ListedIpos = lazy(() => import('../pages/IPOs/ListedIpo'));
const IPODetails = lazy(() => import('../pages/IPOs/IPODetails/IPODetails'));
const Pancards = lazy(() => import('../pages/Pancards/Pancards'));
const Allotment = lazy(() => import('../pages/Allotment/Allotment'));
const ViewAllotment = lazy(() => import('../pages/Allotment/ViewAllotment'));
interface IRoute {
    path: string;
    component: any;
    isPrivateRoute: boolean;
}
const routes: IRoute[] = [
    // {
    //     path: Routing.Login,
    //     component: <Login />,
    //     isPrivateRoute: false,
    // },
    // {
    //     path: Routing.SignUp,
    //     component: <SignUp />,
    //     isPrivateRoute: false,
    // },
    // {
    //     path: Routing.ResetPassword,
    //     component: <ResetPassword />,
    //     isPrivateRoute: false,
    // },
    // {
    //     path: Routing.ForgotPassword,
    //     component: <ForgotPassword />,
    //     isPrivateRoute: false,
    // },
    {
        path: Routing.Dashboard,
        component: <Dashboard />,
        isPrivateRoute: false,
    },
    {
        path: Routing.MainBoardIPOs,
        component: <MainboardIpos />,
        isPrivateRoute: false,
    },
    {
        path: Routing.SMEIPOs,
        component: <SMEIpos />,
        isPrivateRoute: false,
    },
    {
        path: Routing.ListedIPOs,
        component: <ListedIpos />,
        isPrivateRoute: false,
    },
    {
        path: Routing.MainBaordIPODetails,
        component: <IPODetails />,
        isPrivateRoute: false,
    },
    {
        path: Routing.SMEIPODetails,
        component: <IPODetails />,
        isPrivateRoute: false,
    },
    {
        path: Routing.ListedIPODetails,
        component: <IPODetails />,
        isPrivateRoute: false,
    },
    {
        path: Routing.Pancards,
        component: <Pancards />,
        isPrivateRoute: false,
    },
    {
        path: Routing.Pancards,
        component: <Pancards />,
        isPrivateRoute: false,
    },
    {
        path: Routing.Allotment,
        component: <Allotment />,
        isPrivateRoute: false,
    },
    {
        path: Routing.ViewAllotment,
        component: <ViewAllotment />,
        isPrivateRoute: false,
    },
];
export const AppRouting: React.FC = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(showLoader(true));
    });
    return (
        <BrowserRouter>
            <Switch>
                <Route path={routes.filter((route) => !route.isPrivateRoute).map((x) => x.path)}>
                    <MainLayout>
                        <Switch>
                            {routes
                                .filter((route) => !route.isPrivateRoute)
                                .map(({ path, component }, key) => (
                                    <Route
                                        exact
                                        path={path}
                                        component={isNotAuthenticated(() => component)}
                                        key={key}
                                    />
                                ))}
                        </Switch>
                    </MainLayout>
                </Route>
                <Route path={routes.filter((route) => route.isPrivateRoute).map((x) => x.path)}>
                    <MainLayout>
                        <Switch>
                            {routes
                                .filter((route) => route.isPrivateRoute)
                                .map(({ path, component }, key) => (
                                    <Route
                                        exact
                                        path={path}
                                        component={isUserAuthenticated(() => component)}
                                        key={key}
                                    />
                                ))}
                        </Switch>
                    </MainLayout>
                </Route>
                <Redirect to={{ pathname: Routing.Dashboard }} />
            </Switch>
        </BrowserRouter>
    );
};
