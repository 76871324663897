import { combineReducers } from 'redux';
import { AuthReducer } from './auth-reducer';
import { IApplicationState } from '../state/app-state';
import { GeneralReducer } from './general-reducer';

const rootReducer = combineReducers<IApplicationState>({
    IPOData: AuthReducer,
    GeneralData: GeneralReducer,
});

export default rootReducer;
