/* eslint-disable @typescript-eslint/no-explicit-any */

import { AuthActionType } from "../actionTypes/auth-action-type";
import { IAction } from "../state/app-state";
import { IGeneralState } from "../state/auth-state";


export const GeneralReducer = (state: IGeneralState = null, action: IAction<any>): IGeneralState => {
    switch (action.type) {
        case AuthActionType.SHOW_LOADER:
            return {
                ...state,
                showLoader: action.payload,
            };
        default:
            return state;
    }
};
