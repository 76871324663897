/* eslint-disable @typescript-eslint/no-explicit-any */

import { AuthActionType } from '../actionTypes/auth-action-type';
import { IAction } from '../state/app-state';
import { IAuthState } from '../state/auth-state';

export const AuthReducer = (state: IAuthState = null, action: IAction<any>): IAuthState => {
    switch (action.type) {
        case AuthActionType.ADMIN_LOGIN_REQUEST:
            const authConfigs = {
                accessToken: action.payload.accessToken,
            };

            return {
                ...state,
                authConfigs,
                userId: action.payload?.userInfo.userId,
                emailId: action.payload?.userInfo?.email,
                mobileNo: action.payload?.userInfo?.mobileNumber,
            };
        case AuthActionType.ADMIN_LOGOUT_REQUEST:
            return action?.payload;
        // case AuthActionType.ADMIN_CHANGE_LANGUAGE:
        //     return {
        //         ...state,
        //         language: action.payload,
        //     };
        // case AuthActionType.ADMIN_UPDATE_TIMEZONE:
        //     return {
        //         ...state,
        //         timeZoneId: action.payload,
        //     };
        // case AuthActionType.ADMIN_UPDATE_USERNAME:
        //     return {
        //         ...state,
        //         userName: action.payload?.userName,
        //     };
        default:
            return state;
    }
};
